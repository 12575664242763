ion-tab-bar ion-tab-bar ion-tab-button {
  color: var(--ion-color-white);
}

ion-tab-bar ion-icon {
  color: #e1e0e1;
}

ion-tabs ion-tab-bar .tab-selected ion-icon {
  color: var(--ion-color-button);
}

.btn-primary {
  text-transform: inherit;
  --background: var(--ion-color-button);
  --color: var(--ion-color-white);
}

ion-button {
  --box-shadow: none;
  @apply rounded-md;
}

.btn-secondary {
  text-transform: inherit;
  --background: unset;
  color: black;
  border: var(--ion-color-button) 1px solid;
  border-radius: 4px;
}

.btn-login {
  --background: #009a81;
  color: white;
  border-radius: 4px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* ---- HTML renderer ---- */
.render-html p {
  @apply my-4;
}

.render-html img {
  @apply my-8 w-full md:w-auto;
}

.safe-area-top {
  padding-top: 20px;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.save-area-bottom {
  padding-bottom: 20px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
